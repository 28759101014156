<template>
    <div class="main-container">
      <h5 class="pageTitle">정규 메시지 등록</h5>
  
      <div class="form-container">
        <div class="form-group">
          <label for="msgName">템플릿명</label>
          <input v-model="inputMsg" type="text" class="input-box" placeholder="템플릿명을 입력하세요" />          
        </div>
        <div class="form-group">
          <label for="msgContent">템플릿내용</label>
            <div class="template-vars">
              <span @click="insertVariable('${name}')" class="template-tag">이름</span>
              <span @click="insertVariable('${model}')" class="template-tag">모델(타입)</span>
              <span @click="insertVariable('${enddate}')" class="template-tag">만료일1</span>
              <span @click="insertVariable('${YY}.${MM}.${DD}')" class="template-tag">만료일2</span>
            </div>
         
          <textarea 
            v-model="msgContent"  
            rows="2" 
            class="textarea-box"
            @keydown="onKeyDown"
            @click="onClickTextarea"
            @input="onInput"
           >
          </textarea>
          <div style="text-align: right; margin-top: 4px;">({{ currentByte }}/{{ byteLimit }} byte)</div>

        </div>
        
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="registerMsg">등록</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from 'sweetalert2';

  export default {
    data() {
      return {
        templates: [], // 템플릿 목록을 저장할 배열        
        inputMsg:'',
        msgContent: '', // MSG 내용
        lastValidContent: '',
        byteLimit: 90, // 바이트 제한        
      };
    },
    computed: {      
      currentByte() {
        const nameByte = 6;
        const modelByte = 10;
        const dateByte = 2;
        const enddateByte = 8;

        let contentWithVariables = this.msgContent.replace(/\$\{(.*?)\}/g, (match, varName) => {
          if (varName === "name") return "a".repeat(nameByte);
          if (varName === "model") return "a".repeat(modelByte);
          if (["YY", "MM", "DD", "hh", "mm", "ss"].includes(varName)) return "a".repeat(dateByte);
          if (varName === "enddate") return "a".repeat(enddateByte);
          return match;
        });

        let byteCount = 0;
        for (let i = 0; i < contentWithVariables.length; i++) {
          const char = contentWithVariables[i];
          byteCount += char.match(/[가-힣]/) ? 2 : 1;
        }

        return byteCount;
      }
    },
    
    methods: {
      getVariableRanges(text) {
        const regex = /\$\{[^}]+\}/g; // 더 정확하게: ${로 시작해서 }로 끝나는 구조
        let match;
        const ranges = [];
        while ((match = regex.exec(text)) !== null) {
          ranges.push({ start: match.index, end: match.index + match[0].length });
        }
        return ranges;
      },
      onInput(e) {
        if (!e || !e.target || typeof e.target.value === 'undefined') return;
        const text = e.target.value;

        const nameByte = 6;
        const modelByte = 10;
        const dateByte = 2;
        const enddateByte = 8;

        // 변수 치환
        let replaced = text.replace(/\$\{(.*?)\}/g, (match, varName) => {
          if (varName === "name") return "a".repeat(nameByte);
          if (varName === "model") return "a".repeat(modelByte);
          if (["YY", "MM", "DD", "hh", "mm", "ss"].includes(varName)) return "a".repeat(dateByte);
          if (varName === "enddate") return "a".repeat(enddateByte);
          return match;
        });

        // 바이트 계산
        let byteCount = 0;
        for (let i = 0; i < replaced.length; i++) {
          byteCount += replaced[i].match(/[가-힣]/) ? 2 : 1;
        }

        // 90바이트 초과 시 되돌림
        if (byteCount > this.byteLimit) {
          this.msgContent = this.lastValidContent; // 직전 상태로 복원
        } else {
          this.lastValidContent = text; // 정상 입력 저장
        }
      },

      onKeyDown(e) {
        const textarea = e.target;
        const pos = textarea.selectionStart;
        const text = this.msgContent;
        const ranges = this.getVariableRanges(text);

        for (const range of ranges) {
          // 백스페이스
          if (e.key === "Backspace" && pos > range.start && pos <= range.end) {
            e.preventDefault();
            // 전체 삭제 처리
            this.msgContent = text.slice(0, range.start) + text.slice(range.end);
            this.$nextTick(() => {
              textarea.setSelectionRange(range.start, range.start);
            });
            return;
          }

          // Delete
          if (e.key === "Delete" && pos >= range.start && pos < range.end) {
            e.preventDefault();
            this.msgContent = text.slice(0, range.start) + text.slice(range.end);
            this.$nextTick(() => {
              textarea.setSelectionRange(range.start, range.start);
            });
            return;
          }
        }
      },
      
      insertVariable(variable) {
        const textarea = this.$el.querySelector('.textarea-box');
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = this.msgContent;
        this.msgContent = text.substring(0, start) + variable + text.substring(end);
        this.$nextTick(() => {
          textarea.focus();
          textarea.selectionStart = textarea.selectionEnd = start + variable.length;
        });
      },
            
      
      selectText(start, end) {
        this.$nextTick(() => {
          const textarea = this.$el.querySelector(".textarea-box");
          textarea.focus();
          textarea.setSelectionRange(start, end);
        });
      },
      onClickTextarea(e) {
        const textarea = e.target;
        const pos = textarea.selectionStart;
        const text = this.msgContent;
        const ranges = this.getVariableRanges(text);

        for (const range of ranges) {
          if (pos > range.start && pos < range.end) {
            this.selectText(range.start, range.end);
            break;
          }
        }
      },

      // checkByteLimit() {
      //   const nameByte = 6; // ${name} 대체 바이트
      //   const modelByte = 10; // ${model} 대체 바이트
      //   const dateByte = 2; // ${YY}, ${MM}, ${DD}, ${hh}, ${mm} 각각 2바이트로 계산
      //   const enddateByte =3;
      //   // 변수 치환하여 바이트 계산용 문자열 생성
      //   let contentWithVariables = this.msgContent.replace(/\$\{(.*?)\}/g, (match, varName) => {
      //     if (varName === "name") {
      //       return "a".repeat(nameByte);
      //     } else if (varName === "model") {
      //       return "a".repeat(modelByte);
      //     } else if (["YY", "MM", "DD", "hh", "mm", "ss"].includes(varName)) {
      //       return "a".repeat(dateByte); // 날짜/시간 변수 각각 2바이트로 대체
      //     } else if (varName ==="enddate"){
      //       return "a".repeat(enddateByte);
      //     } else {
      //       return match;
      //     }
      //   });

      //   // 총 바이트 수 계산
      //   let byteCount = 0;
      //   for (let i = 0; i < contentWithVariables.length; i++) {
      //     const char = contentWithVariables[i];
      //     byteCount += char.match(/[가-힣]/) ? 2 : 1; // 한글은 2바이트, 나머지 1바이트
      //   }

      //   if (byteCount > 90) {
      //     Swal.fire('오류', `메시지는 90바이트를 넘을 수 없습니다. 현재 ${byteCount}바이트입니다.`, 'error');
      //     return false;
      //   }
      //   else if(byteCount==0){
      //     Swal.fire('오류', `등록된 메시지가 없습니다.. 현재 ${byteCount}바이트입니다.`, 'error');
      //     return false;

      //   } 
      //   else {
      //     // 정상 바이트 메시지 표시 후 등록 버튼 활성화
      //     return Swal.fire({
      //       title: '정상길이',
      //       text: `메시지의 길이는 현재 ${byteCount}바이트입니다.`,
      //       icon: 'success',
      //       showConfirmButton: true
      //     }).then(() => true);
      //   }
      // }
      // ,

      goToList() {
        this.$router.push('/formedsms-read');
      },
      async registerMsg() {

        if (!this.msgContent || this.msgContent.trim() === '') {
          Swal.fire('오류', '메시지가 비어 있습니다.', 'error');
          return;
        }

        if (!this.inputMsg || this.inputMsg.trim() === '') {
          Swal.fire('경고', 'MSG_NAME에 데이터를 입력하세요.', 'warning');
          return;
        }
       
        const tokenData = JSON.parse(sessionStorage.getItem('token'));
        const token = tokenData ? tokenData.access_token : '';
        const config = {
              method: 'post',
              url: `/sms-template/create_formed_messages`,
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`
              },
        
              data: {
                msg_name: this.inputMsg,
                msg_contents: this.msgContent,
              }
        };
        axios(config)
          .then(() => {
              Swal.fire('성공', '메시지가 등록되었습니다.', 'success');
              this.goToList();
          })
          .catch ((error) => {
                Swal.fire('오류', '메시지 등록 중 오류가 발생했습니다.', 'error');
                console.error('메시지 등록 오류:', error);
          });
        
      },
    },
  };
  </script>
  
  <style scoped>
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 10px;
    flex-wrap: nowrap; */
    width: 100%;
  }
  
  .input-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    width: 300px;
  }
  .input-box.wide-input {
    max-width: 300px; /* 발송일시 칸을 조금 더 넓게 */
  }
  .char-count {
    font-size: 0.9em;
    margin-bottom: 5px;
    color: #555;
  }
  .textarea-box {
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    width: 300px;
    height: 90px;     
    resize: none;
  }
  
  .button-container {
    display: flex;
    gap: 150px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .inline-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .type-toggle button {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .type-toggle button.active {
    background-color: #b2b1fc;
    color: white;
  }

  .template-vars {
  margin-bottom: 8px;
}
.template-tag {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
  margin-right: 10px;
  font-size: 14px;
}
  </style>
  